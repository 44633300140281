<template>
  <v-card
    id="app-user-form"
    flat
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-toolbar
      flat
      dense
      color="#f4f3f3"
    >
      <v-toolbar-title>
        App User Form
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      v-model="addFromCSV"
      active-class="white"
      background-color="#f4f3f3"
      color="green"
      grow
    >
      <v-tab
        v-if="!(app_user.isModifingNotCreatedAppUsers && newUsers.length > 0)"
        :key="1"
      >
        Single User
      </v-tab>
      <v-tab
        v-if="!app_user.isModifingAppUser && !(app_user.isModifingNotCreatedAppUsers && newUsers.length < 1)"
        :key="2"
      >
        Multiple User from CSV
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="addFromCSV">
      <v-tab-item
        v-if="!(app_user.isModifingNotCreatedAppUsers && newUsers.length > 0)"
        :key="1"
      >
        <v-card flat>
          <v-card-text>
            <v-form
              ref="appUserForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="appUserForm.username"
                    label="Username*"
                    required
                    :rules="rules.username"
                    :disabled="app_user.appUserDisabled"
                  />
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-model="appUserForm.cognito_user_created"
                    inset
                    label="Activate user"
                    color="success"
                    :disabled="app_user.isModifingAppUser"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="appUserForm._name"
                    label="Name*"
                    required
                    :rules="rules._name"
                    :counter="255"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="appUserForm.surname"
                    label="Surname*"
                    required
                    :rules="rules._name"
                    :counter="255"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="appUserForm.email"
                    label="Email*"
                    required
                    :rules="rules.email"
                    :disabled="app_user.appUserDisabled"
                    :counter="255"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="appUserForm.phone"
                    label="Phone (optional)"
                    :rules="rules.phone"
                    :counter="15"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="appUserForm.language"
                    :items="items"
                    item-value="language"
                    item-text="language"
                    label="Language"
                    :rules="rules.language"
                    class="mt-6"
                    dense
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="appUserForm.user_map_style"
                    :items="mapStyles"
                    item-value="user_map_style"
                    item-text="user_map_style"
                    label="Map Style"
                    :clearable="appUserForm.user_map_style !== ''"
                    class="mt-6"
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>* Campi Obbligatori</span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-if="!(app_user.isModifingNotCreatedAppUsers && newUsers.length < 1)"
        :key="2"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="pb-0">
                <v-file-input
                  v-model="fileCSV"
                  autofocus
                  outlined
                  label="Select CSV file"
                  accept=".csv"
                  placeholder="Uploading csv"
                  prepend-inner-icon="mdi-file-delimited-outline"
                  @change="handleFileUpload"
                />
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-top: 18px"
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadExample()"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download sample CSV file</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="appUserForm.cognito_user_created"
                  inset
                  label="Activate user"
                  color="success"
                  hide-details
                  class="pt-0"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  fixed-header
                  height="300px"
                  :headers="headers"
                  :items="newUsers"
                  no-data-text="No users configured yet."
                  class="elevation-0"
                >
                  <template
                    v-slot:[`item`]="{ item }"
                  >
                    <tr>
                      <td
                        v-for="(header, i) in headers"
                        :key="i"
                      >
                        <div v-if="item.edit === true">
                          <v-btn
                            v-if="header.value === 'actions'"
                            fab
                            :dark="validateInputs(item)"
                            small
                            color="color_green"
                            class="mx-0"
                            :disabled="!validateInputs(item)"
                            @click="item.edit = false"
                          >
                            <v-icon small>
                              mdi-check
                            </v-icon>
                          </v-btn>
                          <v-select
                            v-else-if="header.value === 'language'"
                            v-model="item.language"
                            :items="items"
                            item-value="language"
                            item-text="language"
                            :rules="rules.language"
                            class="table-font-size"
                          />
                          <v-text-field
                            v-else
                            v-model="item[header.value]"
                            :rules="rules[header.value]"
                            class="table-font-size"
                          />
                        </div>
                        <div
                          v-else-if="header.value === 'actions'"
                        >
                          <v-icon
                            small
                            class="mr-2"
                            @click="item.edit = true"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            small
                            @click="deleteItem(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </div>
                        <div v-else>
                          {{ item[header.value] }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-alert
      v-if="customer.defaultService.GLOBAL && customer.defaultService.GLOBAL.service_type"
      dense
      type="warning"
      icon="mdi-alert-outline"
      class="mt-4 mx-4"
      transition="scale-transition"
    >
      Default service already configured with type {{ customer.defaultService.GLOBAL.service_type }}
    </v-alert>
    <v-alert
      v-if="checkSpecificDefaultService.length"
      dense
      type="warning"
      icon="mdi-alert-outline"
      :class="marginTop"
      transition="scale-transition"
    >
      Default service configured for specific group: {{ checkSpecificDefaultService.join(', ') }}
    </v-alert>
    <v-card-actions>
      <v-btn
        v-if="!app_user.isModifingAppUser"
        color="error"
        class="mr-4"
        @click="clear"
      >
        Clear
      </v-btn>
      <v-spacer />
      <v-btn
        :dark="!checkForm()"
        color="color_green"
        class="mr-4"
        :disabled="checkForm()"
        @click="submit"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  required,
  manageLenghtError,
  email_regexp,
  getRegExp,
  checkRegExp
} from '@/formRules.js';
import { S3_BUCKETS, MAP_STYLES } from '@/constants.js';
import AWS from 'aws-sdk';

export default {
  props: {
    appUserForm: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      addFromCSV: 0,
      fileCSV: null,
      formForCall: null,
      valid: true,
      newUsers: [],
      customers: [],
      usernames: [],
      items: [{
        id: 0,
        language: ''
      }, {
        id: 1,
        language: 'IT'
      }, {
        id: 2,
        language: 'EN'
      }],
      mapStyles: ['', ...MAP_STYLES],
      rules: {
        username: [...required, ...getRegExp('space_not_allowed'), ...getRegExp('no_uppercase'), ...manageLenghtError(255)],
        _name: [...required, ...manageLenghtError(255)],
        surname: [...required, ...manageLenghtError(255)],
        email: [...required, ...email_regexp],
        phone: [...getRegExp('phone_number')],
        language: [...required]
      },
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Name', value: '_name', width: '150px' },
        { text: 'Surname', value: 'surname', width: '150px' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone', width: '150px' },
        { text: 'Language', value: 'language', width: '64px' },
        { text: 'Actions', value: 'actions', width: '50px', sortable: false }
      ]
    };
  },
  computed: {
    ...mapState(['app_user', 'customer']),
    checkSpecificDefaultService() {
      const defaultServices = [];
      Object.keys(this.customer.defaultService).forEach(key => {
        if (this.customer.defaultService[key]?.service_type && key !== 'GLOBAL') {
          defaultServices.push(this.customer.defaultService[key].app_scope);
        }
      });

      return defaultServices;
    },
    marginTop() {
      return this.customer.defaultService.GLOBAL.service_type ? 'mt-n3 mx-4' : 'mt-4 mx-4';
    }
  },
  mounted() {
    if (this.app_user.isModifingNotCreatedAppUsers && this.app_user.createAppUser.not_created) {
      if (this.app_user.createAppUser.not_created.length > 1) {
        this.app_user.createAppUser.not_created.forEach(notCreatedUser => {
          notCreatedUser.edit = true;
          delete notCreatedUser.app_user_id;
          delete notCreatedUser._state;
          delete notCreatedUser.cognito_user_created;
          this.newUsers.push(JSON.parse(JSON.stringify(notCreatedUser)));
        });
      }
    } else {
      this.newUsers = [];
    }
  },
  beforeDestroy() {},
  methods: {
    ...mapActions(['storeNewAppUser', 'storeUpdatedAppUser', 'storeAppUsers', 'storeToastMessage']),
    checkForm() {
      this.formForCall = { ...this.appUserForm };

      if (this.app_user.isModifingAppUser && this.valid) {
        return false;
      }

      if (this.app_user.isModifingNotCreatedAppUsers) {
        if (this.newUsers.length >= 1) {
          return this.isEditingCsvUser();
        }
      }

      if (this.addFromCSV) {
        return !(this.newUsers.length > 0 && !this.isEditingCsvUser());
      }

      return !(
        this.valid && this.appUserForm.username !== '' && this.appUserForm._name !== '' &&
        this.appUserForm.surname !== '' && this.appUserForm.email !== '' && this.appUserForm.language !== ''
      );
    },
    async submit() {
      this.overlay = true;

      if (!this.checkForm()) {
        if (this.app_user.isModifingAppUser) {
          await this.storeUpdatedAppUser(this.formForCall);
        } else {
          if (this.app_user.isModifingNotCreatedAppUsers && this.app_user.createAppUser.not_created) {
            if (this.app_user.createAppUser.not_created.length > 1) {
              this.addFromCSV = 1;
            }
          }

          if (this.addFromCSV === 0) {
            this.newUsers.push({ ...this.appUserForm });
          }

          this.newUsers.forEach(user => {
            user._state = this.appUserForm.cognito_user_created;
            delete user.cognito_user_created;
            delete user.edit;
            delete user.app_user_id;
          });

          await this.storeNewAppUser(this.newUsers);
        }
      } else {
        this.storeToastMessage({
          text: 'Please set required fields',
          type: 'warning'
        });
      }

      this.$emit('alert', this.app_user.createAppUser.not_created?.length > 0);

      this.clear();
      this.$emit('closeDialog');
      await this.storeAppUsers(this.app_user.crm_id);
      this.overlay = false;
    },
    clear() {
      this.appUserForm.username = '';
      this.appUserForm._name = '';
      this.appUserForm.surname = '';
      this.appUserForm.email = '';
      this.appUserForm.phone = '';
      this.appUserForm.language = '';
      this.appUserForm.user_map_style = '';
      this.appUserForm._state = false;
      this.appUserForm.cognito_user_created = false;
      this.newUsers = [];
      this.fileCSV = null;
    },
    handleFileUpload() {
      if (!this.fileCSV) {
        this.storeToastMessage({
          text: 'No file selected.',
          type: 'warning'
        });
      } else {
        const reader = new FileReader();

        reader.onload = (event) => {
          const csvData = event.target.result;
          this.parseCSVData(csvData);
        };

        reader.readAsText(this.fileCSV);
        this.fileCSV = null;

        reader.onerror = (event) => {
          this.storeToastMessage({
            text: 'An error occurred while reading the file.' + event,
            type: 'error'
          });
        };
      }
    },
    parseCSVData(data) {
      this.newUsers = [];
      const rows = data.split('\n').map(e => e.replace('\r', ''));
      const headers = ['username', '_name', 'surname', 'email', 'phone', 'language'];
      const fileHeader = rows[0].split(',');
      let correctHeader = fileHeader.length === headers.length;
      let errorAddingUser = false;

      fileHeader.forEach(headCol => {
        if (!headers.includes(headCol) && correctHeader === true) {
          correctHeader = false;
        }
      });

      if (correctHeader) {
        rows.forEach((row, i) => {
          if (i > 0 && !errorAddingUser && row.split(',').length === headers.length) {
            const cols = row.split(',');
            const rowData = {};
            cols.forEach((col, j) => rowData[headers[j]] = cols[j]);
            rowData.username = rowData.username.toLowerCase();
            rowData.edit = false;
            const exists = this.newUsers.find(e => e.username === rowData.username);

            if (this.validateInputs(rowData)) {
              if (!exists) {
                this.newUsers.push(rowData);
              } else {
                this.storeToastMessage({
                  text: 'User not added, duplicate usernames: ' + rowData.username + '. ',
                  type: 'warning'
                });
              }
            } else {
              errorAddingUser = true;
              this.newUsers = [];
              this.storeToastMessage({
                text: 'Error adding user ' + rowData.username + ': invalid inputs. Remember, only "phone" can be empty.',
                type: 'danger'
              });
            }
          }
        });
      } else {
        this.storeToastMessage({
          text: 'CSV header is wrong, download the sample.',
          type: 'danger'
        });
      }
    },
    deleteItem(item) {
      this.deleteIndex = this.newUsers.indexOf(item);
      this.newUsers.splice(this.deleteIndex, 1);
    },
    validateInputs(rowData) {
      if (
        rowData.username == '' || rowData.username.length > 255 || !checkRegExp('space_not_allowed', rowData.username) || !checkRegExp('no_uppercase', rowData.username) ||
        rowData._name == '' || rowData._name.length > 255 ||
        rowData.surname == '' || rowData.surname.length > 255 ||
        rowData.email == '' || !checkRegExp('email', rowData.email) ||
        rowData.language == '' || !checkRegExp('phone_number', rowData.phone)
      ) {
        return false;
      }

      return true;
    },
    isEditingCsvUser() {
      return this.newUsers.some(user => user.edit);
    },
    async downloadExample() {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: 'csv_example/Add_Users_Sample.csv'
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);

      const link = document.createElement('a');
      link.href = response;
      link.target = '_blank';
      link.click();
    }
  }
};
</script>

<style scoped>
  .table-font-size{
    font-size: 0.875rem;
  }
</style>
